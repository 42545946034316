body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #552255;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    background: #4c2a4c;
    margin: 20px auto;
    width: 90%;
    max-width: 700px;
    color: #eee;
}
.navbar {
    padding: 10px 20px;
    text-align: center;
    background: #6d3d6d;
}
.navbar h1 {
    margin: 10px 0;
}

.task-list {
    margin: 20px;
    padding-bottom: 10px;
}
.task-list ul {
    padding: 0;
    list-style-type: none;
}
.task-list li {
    background: #6d3d6d;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    margin: 10px 0;
}
.task-list li:hover {
    opacity: 0.7;
    text-decoration: line-through;
}
.task-list .title {
    font-weight: bold;
    color: #fff;
    font-size: 1.1em;
}
.task-list .author {
    font-size: 0.9em;
    color: #ddd;
}
.empty {
    margin: 20px;
    text-align: center;
    padding-bottom: 20px;
}

form {
    padding: 20px 20px 0px 20px;
}
input[type='text'] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: 6px 0;
    background: #3c1f3c;
    color: #fff;
    border: 0;
}
input[type='submit'] {
    margin: 10px auto;
    background: #3c1f3c;
    color: #ffb4ff;
    border: 2px solid #5a3c5a;
    border-radius: 10px;
    padding: 10px 30px;
    display: block;
}
